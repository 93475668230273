// React
import React, {useState} from 'react';

// Chrakra ui
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
  useMediaQuery,
  Image,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react';

// Chrakra-ui-icons
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

// React-router-dom
import { useNavigate } from 'react-router-dom';

// Images
const brandLogoLandingIcon = require('../../../assets/images/brand_logo/brand-logo-icon-landing.png');

const Links = [{name: 'Inicio', url: '/'}, {name: 'Nosotros', url: '/about'}];

const NavLink = () => {

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      
      href={'#'}>
    </Box>
  )
}

const LandingNavbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSmallerScreen] = useMediaQuery('(max-width: 900px)');
  const [placement, setPlacement] = React.useState('left');
  const [activeLocation, setActiveLocation] = useState(false);
  const navigate = useNavigate();

  const openMenu = () => {
    onOpen();
  }

  const closeMenu = () => {
    onClose();
  }

  const redirect = (location) => {
    navigate(location);
    onClose();
  }

  return (
    <>
    {isSmallerScreen && (
      <Drawer placement={placement} isOpen={isOpen} className='collapsible-menu'>
        <DrawerOverlay />
        <DrawerContent bg={'#007472'}>
          <DrawerHeader borderBottomWidth='0px'>
          <Box w='100%'>
            <Flex justifyContent={'end'} alignItems='center'>
              <CloseIcon cursor={'pointer'} onClick={() => closeMenu()} h='15px' w='15px' color='#FFF'/>
            </Flex>
          </Box>
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing={'10px'}>
              <Text fontWeight={'600'} cursor='pointer' fontSize='16px' color='#FFF' onClick={() => navigate('/')}>Inicio</Text>
              <Text fontWeight={'600'} cursor='pointer' fontSize='16px' color='#FFF' onClick={() => navigate('/about')}>Nosotros</Text>
              {/* <Text fontWeight={'600'} cursor='pointer' fontSize='16px' color='#FFF' onClick={() => redirect('/')}>Contacto</Text> */}
              <Button onClick={() => navigate('/login')} sx={{border: '1px solid #F1352B'}} bg='#F1352B' pt='8px' pb='8px' pl='50px' pr='50px' colorScheme='none' h='40px' w={isSmallerScreen ? '150px' : '200px'} borderRadius='30px'>
                  <Text fontWeight={'700'} fontSize='16px' color='#FFF'>Entrar</Text>
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )}

    <Box margin={'0 auto'} maxW='1280px' pt='20px'>
      <Box bg={'transparent'} px={4} maxW='1280px'>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            bg={'none'}
            onClick={() => openMenu()}
          />
          <Box spacing={8} alignItems={'center'} w='100%' pl={isSmallerScreen ? '' :  '100px'} pr={isSmallerScreen ? '' :  '100px'}>
            <Flex justifyContent={'space-between'} gap={isSmallerScreen && '40px'} flexDirection={'row'} alignItems='center'>
                {isSmallerScreen ? (
                  <Flex w='100%' justifyContent={'end'}>
                    <Image src={brandLogoLandingIcon} h='42px' w='auto' />
                  </Flex>
                ):(
                  <Box>
                    <Image src={brandLogoLandingIcon} h='42px' w='auto' />
                  </Box>
                )}
                <HStack as={'nav'} spacing={'40px'} display={{ base: 'none', md: 'flex' }}>
                  {Links.map((link, ix) => {
                      return(
                          <Text fontWeight={'600'} onClick={() => navigate(link.url)} cursor='pointer' fontSize='16px' color='#235800' key={ix}>{link.name}</Text>
                      )
                  })}
                  <Button onClick={() => navigate('/before-signup')} bg='#F1352B' pt='8px' pb='8px' pl='50px' pr='50px' colorScheme='none' h='42px' w={isSmallerScreen ? '150px' : '200px'} borderRadius='50px'>
                      <Text fontWeight={'700'} fontSize='16px' color='white'>Registrate</Text>
                  </Button>
                  <Button onClick={() => navigate('/login')} sx={{border: '1px solid #F1352B'}} pt='8px' pb='8px' pl='50px' pr='50px' colorScheme='none' h='42px' w={isSmallerScreen ? '150px' : '200px'} borderRadius='50px'>
                      <Text fontWeight={'700'} fontSize='16px' color='#F1352B'>Entrar</Text>
                  </Button>
                </HStack>
            </Flex>
          </Box>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
    </>
  )
}

export default LandingNavbar;