// React
import React, { useEffect, useState } from "react";

// React-router-dom
import { Link as LinkRouter, useNavigate } from 'react-router-dom';

// Components
import InformationVerificationRequestModal from '../InformationVerificationRequestModal';

// Client
import { supabase } from '../../client';

// Chakra-ui
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Box,
  useOutsideClick,
  useMediaQuery,
  Tag,
  Flex
} from '@chakra-ui/react';

const CreateModal = ({isOpen, onOpen, onClose, optionButtonCreateSelected, closeCreateModal, isUserVerified}) => {
  const navigate = useNavigate();
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [isVerificationInProcess, setIsVerificationInProcess] = useState(false);
  const [isVerificationRejected, setIsVerificationRejected] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [type, setType] = useState(null);
  const ref = React.useRef();
  useOutsideClick({
    ref: ref,
    handler: () => closeCreateModal(),
  });

  useEffect(() => {
    getVerificationRequestForUser();
  }, []);

  const getVerificationRequestForUser = async() => {
    const currentUserId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('verification_requests')
      .select('verification_status')
      .eq('user_id', currentUserId)
      if(data && data.length > 0) {
        const { verification_status } = data[0];
        if(verification_status === 'in_process') setVerificationStatus('in_process');
        if(verification_status === 'rejected') setVerificationStatus('rejected');
      }
    } catch (err) {
      console.log('There was an error getting the verification request for user ', err);
    }
  }

  const redirectToCreate = (type) => {
    if(isUserVerified === true) {
      navigate(`/${type}/create`);
    } else {
      setIsOpenVerificationModal(true);
      setType(type);
    }
  }

  const closeVerificationModal = () => {
    setIsOpenVerificationModal(false);
  }

  const displayMessageTag = () => {
    if(verificationStatus === 'in_process') {
      return <Tag bg='orange.400' color='#fff' textAlign={isSmallerScreen ? 'center' : 'left'}>Tu cuenta está en proceso de verificación</Tag>;
    }
    if(verificationStatus === 'rejected') {
      return <Tag bg='#F1352B' color='#fff'>Tu verificación se rechazó</Tag>;
    }
  }

  return(
    <>
    <Modal ref={ref} isOpen={isOpen} onClose={onClose} isCentered={isSmallerScreen ? false : true}>
      <ModalOverlay
       bg='rgba(0, 0, 0, 0.50)'
       backdropFilter='blur(0px)'
      />
      <ModalContent  p='24px 40px' borderRadius={'10px'} w={isSmallerScreen ? '95%' : '480px'}>
        <ModalHeader
         textAlign='center'
         color='#151515'
         fontSize={'20px'}
         fontWeight='600'
         lineHeight={'140%'}
         letterSpacing='0.15px'
        >¿Qué te gustaría publicar?</ModalHeader>
        
        <ModalBody>
          <Box>
            <Stack spacing={'20px'}>
              <Box align='center'>
                <Button
                isDisabled={verificationStatus === 'in_process' || verificationStatus === 'rejected' ? true : false} 
                onClick={() => redirectToCreate('posts')} 
                bg='#FFF' 
                sx={{border: '1px solid #007472'}} 
                p='8px 44px' 
                borderRadius={'100px'} 
                w='100%'>

                  <Text
                  color='#007472'
                  fontSize={'18px'}
                  fontWeight='700'
                  lineHeight={'140%'}
                  letterSpacing={'0.15px'}
                  >Acción</Text>
                </Button>
              </Box>

              <Box align='center'>
                <Button
                  isDisabled={verificationStatus === 'in_process' || verificationStatus === 'rejected' ? true : false} 
                  onClick={() => redirectToCreate('initiatives')} 
                  bg='#FFF' 
                  sx={{border: '1px solid #007472'}} 
                  p='8px 44px' 
                  borderRadius={'100px'} 
                  w='100%'>
                    <Text
                    color='#007472'
                    fontSize={'18px'}
                    fontWeight='700'
                    lineHeight={'140%'}
                    letterSpacing={'0.15px'}
                    >Iniciativa</Text>
                </Button>
              </Box>
              <Box align='center' position={'relative'}>
                <Button 
                isDisabled={verificationStatus === 'in_process' || verificationStatus === 'rejected' ? true : false} 
                onClick={() => redirectToCreate('events')} 
                bg='#FFF' 
                sx={{border: '1px solid #007472'}} 
                p='8px 44px' 
                borderRadius={'100px'} w='100%'
                >
                  <Text
                  color='#007472'
                  fontSize={'18px'}
                  fontWeight='700'
                  lineHeight={'140%'}
                  letterSpacing={'0.15px'}
                  >Evento</Text>
                </Button>
              </Box>
              <Flex justifyContent={'center'}>
                  <Box top='0px'>
                    {displayMessageTag()}
                  </Box>
                </Flex>

            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>

    <InformationVerificationRequestModal
    isOpenVerificationModal={isOpenVerificationModal}
    closeVerificationModal={closeVerificationModal}
    type={type}
    />

    </>
  )
}

export default CreateModal;