// React
import React, { useEffect, useState } from "react";

// Chakra-ui
import {
  Text,
  Box,
  Stack,
  Flex,
  Image
} from '@chakra-ui/react';

// Client
import { supabase } from '../../../../../../client';

const ActionsNumber = () => {
  const [totalPosts, setTotalPosts] = useState(0);
  useEffect(() => {
    getNumberOfPostsByUser();
  }, []);
  
  const getNumberOfPostsByUser = async() => {
    const user_id = await supabase.auth.user().id;
    try {
      const { data, error } = await supabase
      .from('posts')
      .select('id')
      if(data && data.length > 0) {
        setTotalPosts(data.length)
      }
    } catch (err) {
      console.log('error fetching number of actions ', err);
    }
  }

  return(
    <Box w={'346px'} p='24px' h={'108px'} bg='#DEEFF0' borderRadius={'16px'}>
      <Stack spacing={'10px'}>
        <Text fontWeight='700' color='#1C1C1C' lineHeight={'143%'} letterSpacing='0.1px'>Acciones totales realizadas</Text>
        <Text fontSize='24px' color='#1C1C1C' textAlign={'left'} fontWeight='700' lineHeight={'133.4%'}>{totalPosts}</Text>
      </Stack>
    </Box>
  )
} 

export default ActionsNumber;