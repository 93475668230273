// React
import React, { useState, useRef, useEffect } from 'react';

// Supabase client
import { supabase } from '../../client';

// React-Router-dom
import { useNavigate } from 'react-router-dom';

// React-webcam
import Webcam from "react-webcam";

// Axios
import axios from 'axios';

// UID
import { v4 as uuidv4 } from 'uuid';

// Chakra-ui
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
  Box,
  useOutsideClick,
  useMediaQuery,
  FormControl,
  InputGroup,
  Input,
  Flex,
  ScaleFade,
  Tooltip,
  Textarea,
  Image,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

// Images && Icons
const successIcon = require('../../assets/images/payment_icons/payment-success.png');

const InformationVerificationRequestModal = ({isOpenVerificationModal, closeVerificationModal, type}) => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [currentStepVerification, setCurrentStepVerification] = useState(0);
  const [verificationInfo, setVerificationInfo] = useState({});
  const [userSelfie, setUserSelfie] = useState(null);
  const [smallSelfiePreview, setSmallSelfiePreview] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async() => {
    const currentUserId = await supabase.auth.user().id;
    const userEmail = await supabase.auth.user().email;

    try {
      const { data } = await supabase
      .from('profiles')
      .select(`username, name`)
      .eq('id', currentUserId)
      if(data && data.length > 0) {
        const { username, name } = data[0];
        const newUserName = username ? username : name;
        setCurrentUserName(newUserName);
        setCurrentUserEmail(userEmail);
      }
    } catch (err) {
      console.log('There was an error getting the user ', err);
    }
  }

  const handleVerificationInformation = (e) => {
    const { name, value } = e.target;
    const newUserInfo = {[name]: value};
    const newData = {...verificationInfo, ...newUserInfo};
    setVerificationInfo(newData);
  }

  const emptyFormToaster = () => {
    return(
      toast({
        title: 'Faltan datos por agregar',
        description: "Asegurate de completar todo el formulario.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const emptySelfieToaster = () => {
    return(
      toast({
        title: 'Falta tu foto',
        description: "Asegurate de subir tu foto.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const startVerificationStep = () => {
    let newStep = currentStepVerification + 1;
    setCurrentStepVerification(newStep);
  }

  const nextVerificationStep = () => {
    if(
      verificationInfo['phone_number'] === undefined ||
      verificationInfo['instagram_url'] === undefined ||
      (verificationInfo['about_you'] === undefined && (type === 'events' || type === 'initiatives'))
      ) {
        return emptyFormToaster();
      } else {
        let newStep = currentStepVerification + 1;
        setCurrentStepVerification(newStep);
      }
  }

  const prevVerificationStep = () => {
    let newPrevStep = currentStepVerification - 1;
    setCurrentStepVerification(newPrevStep);
  }

  const closeAccountVerificationModal = () => {
    closeVerificationModal();
  }

  const sendUserEmailNotification = (verificationInfo) => {
    const userInfo = {
      name: verificationInfo.name,
      email: verificationInfo.email
    }

    try {
      const emailApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NODE_LOCAL_API_URL : process.env.REACT_APP_NODE_PRODUCTION_API_URL;
      axios({
        method: 'post',
        url: `${emailApiUrl}/account-verification-email-in-process`,
        data: {userInfo}
      });
    } catch (err) {
      console.log('There was an error sending the email to the user', err);
    }
  }

  const capturePicture = (getScreenshot) => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], "File name",{ type: "image/png" })
      setUserSelfie(file);
      setSmallSelfiePreview(imageSrc);
    });
  };

  const uploadUserSelfieToBucket = async(file, fileName) => {
    try {
      const { data, error, status } = await supabase
      .storage
      .from('verification_user_selfie')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      });
      if(data && Object.keys(data).length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log('There was an error uploading the file to the bucket ', err.message)
    }
  }

  const sendVerificationInfo = async() => {

    if(!userSelfie) {
      return  emptySelfieToaster();
    }

    const currentUserId = await supabase.auth.user().id;
    const newFileName = `${uuidv4()}.png`;

    verificationInfo['name'] = currentUserName;
    verificationInfo['email'] = currentUserEmail;
    verificationInfo['user_selfie'] = newFileName;
    verificationInfo['user_id'] = currentUserId;

    let hasUserSelfieUploaded = await uploadUserSelfieToBucket(userSelfie, newFileName);
    
    if(hasUserSelfieUploaded === true) {
      try {
        const { data } = await supabase
        .from('verification_requests')
        .insert(verificationInfo)
        if(data && data.length > 0) {
          setCurrentStepVerification(currentStepVerification + 1);
          sendUserEmailNotification(verificationInfo);
        }
      } catch (err) {
        console.log('There was an error uploading the verification request ', err);
      }
    }
  }

  const diplayVerificationSteps = () => {

    switch (currentStepVerification) {
      case 0:
        return initialStepComponent();
        break;

      case 1:
        return secondStepComponent();
        break;

      case 2:
        return thirdStepComponent();
        break;

      case 3:
        return finalStepVerificationComponent();
        break;
    
      default:
        break;
    }
  }

  const initialStepComponent = () => {
    return(
      <ModalContent p='20px 8px' borderRadius={'10px'} minW={isSmallerScreen ? '95%' : '700px'}>
        <Flex justifyContent={'center'}>
          <Box w='100%' >
            <Text
            textAlign='center'
            color='#151515'
            fontSize={'18px'}
            fontWeight='400'
            lineHeight={'140%'}
            letterSpacing='0.15px'
            pb={'10px'}
            >Antes de que puedas crear un evento, iniciativa o acción         
             emocionante, necesitamos conocerte un poco mejor.    
             Por favor, continua con el proceso de verificación
             haciendo clic en el botón <Text as='b' fontWeight={'600'}>continuar</Text>.</Text>
          </Box>
        </Flex>
        
        <ModalBody>
          <Stack spacing={'20px'}>
            <Box>
              <Flex justifyContent={'center'}>
                <Button p={'8px 44px'} onClick={() => startVerificationStep()} colorScheme='none' bg='#F1352B' borderRadius={'100'} w='285px'>
                  <Text color='#FFF'>Continuar</Text>
                </Button>
              </Flex>

              <Flex justifyContent={'center'} mt='10px'>
                <Button p={'8px 44px'} onClick={() => closeAccountVerificationModal()} colorScheme='none' border='1px solid #F1352B' bg='#FFF' borderRadius={'100'} w='285px'>
                  <Text color='#F1352B'>Cancelar</Text>
                </Button>
              </Flex>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    )
  }

  const secondStepComponent = () => {
    return(
      <ModalContent p='20px 8px' borderRadius={'10px'} minW={isSmallerScreen ? '95%' : '700px'}>
        <Flex justifyContent={'center'}>
          <Box w='80%' >
            <Text
            textAlign='center'
            color='#151515'
            fontSize={'18px'}
            fontWeight='400'
            lineHeight={'140%'}
            letterSpacing='0.15px'
            pb={'10px'}
            >Paso 1: Completa tu perfil para la verificación</Text>
          </Box>
        </Flex>
        
        <ModalBody>
          <Stack spacing={'20px'}>
            <Box>
              <Flex gap={'8px'}>
                <Box w='100%'>
                  <FormControl position={'relative'}>
                    <Box position='absolute' mt={'-9px'} bg='white' pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
                    <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Nombre Completo</Text>
                    </Box>
                    <Input onChange={(e) => handleVerificationInformation(e)} defaultValue={currentUserName} borderRadius='8px' _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} h='52px' placeholder='Ingrese su nombre' name="name"></Input>
                  </FormControl>
                </Box>
              </Flex>
            </Box>

            <Box>
              <Flex gap={'8px'} flexWrap={isSmallerScreen ? 'wrap' : 'nowrap'}>
                <Box w='100%'>
                  <FormControl position={'relative'}>
                    <Box position='absolute' mt={'-9px'} bg='white' pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
                    <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Número de Teléfono</Text>
                    </Box>
                    <Input onChange={(e) => handleVerificationInformation(e)} defaultValue={''} borderRadius='8px' _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} h='52px' placeholder='Ingrese su telefono' name="phone_number"></Input>
                  </FormControl>
                </Box>

                <Box w='100%' mt={isSmallerScreen ? '10px' : '0'}>
                  <FormControl position={'relative'}>
                    <Box position='absolute' mt={'-9px'} bg='white' pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
                    <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Correo Electrónico</Text>
                    </Box>
                    <Input isDisabled={true} onChange={(e) => handleVerificationInformation(e)} defaultValue={currentUserEmail} borderRadius='8px' _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} h='52px' placeholder='Ingrese su email' name="email"></Input>
                  </FormControl>
                </Box>
              </Flex>
            </Box>

            <Box>
              <Flex justifyContent='left' flexWrap={isSmallerScreen && 'wrap'} gap={isSmallerScreen ? '20px' : '8px'}>
                <Box w='100%'>
                  <FormControl position={'relative'}>
                    <Box position='absolute' mt={'-9px'} bg='white' pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
                      <Tooltip label="Tu instagram debe tener fotos de eventos pasados y debes aparecer en alguna imagen." bg="teal.500" color="white">
                        <Text textDecoration="underline" fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Instagram</Text>
                      </Tooltip>
                    </Box>

                    
                    <Input onChange={(e) => handleVerificationInformation(e)} defaultValue={''} borderRadius='8px' _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} h='52px' placeholder='Ingrese su instagram' name="instagram_url"></Input>
                  </FormControl>
                </Box>

                <Box w='100%'>
                  <FormControl position={'relative'}>
                    <Box position='absolute' mt={'-9px'} bg='white' pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
                    <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>{`Website de tu empresa (opcional)`}</Text>
                    </Box>
                    <Input onChange={(e) => handleVerificationInformation(e)} defaultValue={''} borderRadius='8px' _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} h='52px' placeholder='Ingrese su instagram' name="website_url"></Input>
                  </FormControl>
                </Box>
              </Flex>
            </Box>

            {(type && type === 'initiatives' || type === 'events') && (
              <Box>
              <FormControl position={'relative'}>
                <Box position='absolute' mt={'-9px'} bg='white' pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
                <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Cuentanos de tu iniciativa o eventos.</Text>
                </Box>
                <Textarea onChange={(e) => handleVerificationInformation(e)} defaultValue={''} borderRadius='8px' _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} h='52px' placeholder='Cuentanos mas de tus experiencias, eventos pasados.' name="about_you"></Textarea>
              </FormControl>
              </Box>
            )}

            <Box>
              <Flex justifyContent={'center'}>
                <Button p={'8px 44px'} onClick={() => nextVerificationStep()} colorScheme='none' bg='#F1352B' borderRadius={'100'} w='285px'>
                  <Text color='#FFF'>Continuar</Text>
                </Button>
              </Flex>

              <Flex justifyContent={'center'} mt='10px'>
                <Button p={'8px 44px'} onClick={() => closeAccountVerificationModal()} colorScheme='none' border='1px solid #F1352B' bg='#FFF' borderRadius={'100'} w='285px'>
                  <Text color='#F1352B'>Cancelar</Text>
                </Button>
              </Flex>
            </Box>
            
          </Stack>
        </ModalBody>
      </ModalContent>
    )
  }

  const thirdStepComponent = () => {
    return(
      <ModalContent p='20px 8px' borderRadius={'10px'} minW={isSmallerScreen ? '95%' : '700px'}>
        <Flex justifyContent={'center'}>
          <Box w='80%' >
            <Text
            textAlign='center'
            color='#151515'
            fontSize={'18px'}
            fontWeight='400'
            lineHeight={'140%'}
            letterSpacing='0.15px'
            pb={'10px'}
            >Paso 2: Sube tu foto para completar la verificación</Text>
          </Box>
        </Flex>
        
        <ModalBody>
          <Stack spacing={'40px'}>
            <WebcamCapture />
            <Box>
              <Flex justifyContent={'center'}>
                <Button isDisabled={userSelfie ? false : true} p={'8px 44px'} onClick={() => sendVerificationInfo()} colorScheme='none' bg='#F1352B' borderRadius={'100'} w='285px'>
                  <Text color='#FFF'>Finalizar</Text>
                </Button>
              </Flex>

              <Flex justifyContent={'center'} mt='10px'>
                <Button p={'8px 44px'} onClick={() => prevVerificationStep()} colorScheme='none' border='1px solid #F1352B' bg='#FFF' borderRadius={'100'} w='285px'>
                  <Text color='#F1352B'>Volver atrás</Text>
                </Button>
              </Flex>
            </Box>
            
          </Stack>
        </ModalBody>
      </ModalContent>
    )
  }

  const finalStepVerificationComponent = () => {
    return(
      <ModalContent p='20px 8px' borderRadius={'10px'} minW={isSmallerScreen ? '95%' : '700px'}>
        
        <ModalBody>
          <Stack spacing={'40px'}>
            <Box>
              <Stack spacing={'20px'}>
                <Flex justifyContent={'center'}>
                  <Image src={successIcon} h='150px' maxW='150px'/>
                </Flex>
                <Box w='100%' display={'flex'} justifyContent='center'>
                  <Text
                  textAlign='center'
                  color='#151515'
                  fontSize={'18px'}
                  fontWeight='400'
                  w='70%'
                  lineHeight={'140%'}
                  letterSpacing='0.15px'
                  pb={'10px'}
                  >¡Gracias!, tu cuenta ha pasado a un proceso de verificación. Dentro de 24 horas te notificaremos a tu email.
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Flex justifyContent={'center'} mt='10px'>
                <Button p={'8px 44px'} onClick={() => closeAccountVerificationModal()} colorScheme='none' border='1px solid #F1352B' bg='#FFF' borderRadius={'100'} w='285px'>
                  <Text color='#F1352B'>Cerrar</Text>
                </Button>
              </Flex>
            </Box>
            
          </Stack>
        </ModalBody>
      </ModalContent>
    )
  }

  const WebcamCapture = () => (
    <>
    <Webcam
      audio={false}
      height={'200px'}
      screenshotFormat="image/jpeg"
      width={'100%'}
      ref={webcamRef}
      screenshotQuality={0.8}
      mirrored={true}
      videoConstraints={videoConstraints}
    >
      {({ getScreenshot }) => (
         <Flex justifyContent={'center'}>
          <Button p={'8px 20px'} bg='#007472' onClick={() => capturePicture(getScreenshot)} colorScheme='none' borderRadius={'100px'} w='max-content'>
            <Text color='#FFF'>Sacar Foto</Text>
          </Button>
         </Flex>
      )}
    </Webcam>
    {smallSelfiePreview && (
      <Box 
      position={'absolute'} 
      h={isSmallerScreen ? '100px' : '200px'}
      w={isSmallerScreen ? '100px' : '200px'}
      >
        <Image src={smallSelfiePreview} />
      </Box>
    )}
    </>
  );

  return(
    <>
    <ScaleFade initialScale={0.9} in={isOpenVerificationModal}>
      <Modal isOpen={isOpenVerificationModal}>
        <ModalOverlay
        bg='rgba(0, 0, 0, 0.50)'
        backdropFilter='blur(0px)'
        />
        {diplayVerificationSteps()}
      </Modal>
    </ScaleFade>
    </>
  )
}

export default InformationVerificationRequestModal;