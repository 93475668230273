// React
import React from 'react';

// Chakra-ui
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  useDisclosure,
  Flex
} from '@chakra-ui/react';

const SelfieModal = ({isOpenSelfieModal, selfieUrl, closeSelfieModal}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const url = `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SUPABASE_LOCAL_URL : process.env.REACT_APP_SUPABASE_URL}`; 

  return(
    <Modal isOpen={isOpenSelfieModal}>
      <ModalOverlay />
      <ModalContent p={3}>
        <ModalBody>
          <Image src={`${url}/storage/v1/object/public/verification_user_selfie/${selfieUrl}`}/>
        </ModalBody>

        <Flex justifyContent={'center'}>
          <Button color='#007472' h='30px' onClick={closeSelfieModal} colorScheme={'none'} border='1px solid #007472' mr={3}>
            Cerrar
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default SelfieModal;