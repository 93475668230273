// React
import React, { useEffect, useState } from 'react';

// Axios
import axios from 'axios';

// Components
import ActionsNumber from './components/ActionsNumber';
import InitiativesNumber from './components/InitiativesNumber';
import TotalAmountCollected from './components/TotalAmountCollected';
import UserRegisteredNumber from './components/UserRegisteredNumber';
import PostsList from '../PostsList';
import VerificationRequestsList from '../VerificationRequestsList';
import CreateMunicipalityForm from './components/CreateMunicipalityForm';

// Chakra-ui
import {
  Text,
  Box,
  Stack,
  Flex,
  Image,
  useMediaQuery,
  Button,
  ScaleFade,
  Input,
  Select,
  useToast,
  Table,
  TableContainer,
  Th,
  Td,
  Thead,
  Tr,
  Tbody
} from '@chakra-ui/react';

// Client
import { supabase } from '../../../../client';

const InitiativesList = () => {
  const toast = useToast();
  const [isSmallerScreenIpad] = useMediaQuery('(max-width: 780px)');
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [isOpenAddMunicipalityForm, setIsOpenAddMunicipalityForm] = useState(false);
  const [isCreatingMunicipality, setIsCreatingMunicipality] = useState(false);
  const [municipalityId, setMunicipalityId] = useState(null);
  const [createdUserId, setCreatedUserId] = useState(null);
  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    getMunicipalities();
  }, []);

  const getMunicipalities = async() => {  
    try {
      const { data, error } = await supabase.rpc('get_municipalities')
      if(data && data.length > 0) {
        setMunicipalities(data);
      }
    } catch (err) {
      console.log('There was an error trying to get the municipalities ', err);
    }
  }

  const openCreateMunicipalityForm = () => {
    setIsOpenAddMunicipalityForm(!isOpenAddMunicipalityForm);
  }

  const errorCreatingMunicipalityToaster = () => {
    return(
      toast({
        title: 'Hubo un error',
        description: "No se ha creado la municipalidad",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const municipalityCreatedToaster = () => {
    return(
      toast({
        title: 'Se ha creado la municipalidad',
        description: "Operación realizada con éxito",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const createNewMunicipality = async(municipality) => {
    const { name, region_name, city_name, adminEmail } = municipality;
    setIsCreatingMunicipality(true);
    try {
      const { data, error } = await supabase
      .from('municipalities')
      .insert({ name, region_name, city_name});
      if(error) {
        return errorCreatingMunicipalityToaster();
      }

      if(data.length > 0) {
        setMunicipalityId(data[0].id);
        createNewUserMunicipality(name, adminEmail, data[0].id);
      }

    } catch (err) {
      console.log('There was an error creating municipality ', err);
    }
    setIsCreatingMunicipality(true);
  }

  const createNewUserMunicipality = async(name, email, muniId) => {
    const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NODE_LOCAL_API_URL : process.env.REACT_APP_NODE_PRODUCTION_API_URL;

    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=[]{}|;:,.<>?";
    let password = "";
    let length = 12;
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    try {
      
      axios.post(`${apiUrl}/create-user`,{
        email: email,
        name: name,
        password: password
      })
      .then(function (response) {
        const { data: { userId } } = response;
        createRelationOnUserRolesTable(userId, muniId, email, name, password);

      })
      .catch(function (error) {
        console.log('There was an error with the api crate user', error);
      });
    
    } catch (err) {
      console.log('There was an error creating user municipality', err);
    }
  }

  const createRelationOnUserRolesTable = async(userId, muniId, email, name, password) => {
    try {
      const { data, error } = await supabase
      .from('user_roles')
      .insert({
        role_id: 5,
        user_id: userId
      })

      if(data.length > 0) {
        createRelationOnMunicipalityWorkerTable(userId, muniId, email, name, password);
      }
    } catch (err) {
      console.log('There was an error creating role table', err);
    }
  }

  const createRelationOnMunicipalityWorkerTable = async(userId, muniId, email, name, password) => {
    try {
      const { data, error } = await supabase
      .from('municipality_workers')
      .insert({
        user_id: userId,
        municipality_id: muniId,
        worker_email: email
      });
      if(data.length > 0) {
        municipalityCreatedToaster();
        setIsCreatingMunicipality(false);
        setIsOpenAddMunicipalityForm(false);
        const newMunicipality = {
          name: name,
          worker_email: email
        }
        const newMunicipalities = municipalities.concat(newMunicipality);
    
        setMunicipalities(newMunicipalities);
        sendWelcomeEmailToMunicipalityAdministrator(email, password);
      }
    } catch (error) {
      console.log('There was an error creating the relation on table municipality worker', error);
    }
    setIsCreatingMunicipality(false);
  }

  const sendWelcomeEmailToMunicipalityAdministrator = async(email, password) => {
    const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NODE_LOCAL_API_URL : process.env.REACT_APP_NODE_PRODUCTION_API_URL;
    try {
      axios.post(`${apiUrl}/welcome-email-municipality`,{
        email: email,
        password: password
      })
      .catch(function (error) {
        console.log('There was an error sending email', error);
      });
    } catch (err) {
      console.log('Couldn\'t send welcome email to email ', err);
    }
  }

  const municipalitiesTable = () => {
    return(
      <Box pb='20px' w={'100%'}>
        <TableContainer overflowY={'auto'} maxH={isSmallerScreen ? '300px' : '400px'} w={'100%'} borderRadius={'10px'} border='1px solid #DEEFF0' boxShadow={'0px 5px 14px 0px #E4E8EE'}>
          <Table>
            <Thead position={'sticky'} top={0} bg='#FFF'>
              <Tr>
                <Th>Nombre Municipalidad</Th>
                <Th>Email Administrador</Th>
              </Tr>
            </Thead>
            <Tbody w='100%'>
              {municipalities.map((element, ix) => {
                return(
                  <Tr key={ix}>
                    <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.name}</Td>
                    <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.worker_email}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box> 
    )
  }

  return(
    <Box pl={isSmallerScreen ? '0px' : '40px'} maxW='1280px' h='auto' pb='40px'>
      <Box mt={'10px'}>
        <Stack spacing={8}>
          <Box w={'100%'}>
            <Text color={'#000'} fontSize={'20px'} pl={isSmallerScreen ? '16px' : ''} fontWeight='400' mb='10px' lineHeight={'140%'} letterSpacing='0.15px'>General</Text>
            <Flex flexDir={'row'} gap={'20px'} flexWrap='wrap' justifyContent={isSmallerScreen ? 'center' : 'left'}>
              <ActionsNumber />
              <InitiativesNumber />
              <TotalAmountCollected />
              <UserRegisteredNumber />
            </Flex>
          </Box>

          <Box w='100%'>
            <PostsList />
          </Box>

          <Box>
            <VerificationRequestsList />
          </Box>

          <Box p='0px 0px 0px 20px' w='90%'>
            <Stack spacing={'20px'}>
              <Box>
                <Text color={'#000'} fontSize={'20px'} pl={isSmallerScreen ? '16px' : ''} fontWeight='400' mb='10px' lineHeight={'140%'} letterSpacing='0.15px'>Agrega Municipalidades</Text>
                <Button w='max-content' onClick={(e) => openCreateMunicipalityForm(e)}>{`${isOpenAddMunicipalityForm ? 'Cancelar' : 'Agregar Municipalidad'}`}</Button>
              </Box>
              {isOpenAddMunicipalityForm && (
                <CreateMunicipalityForm 
                isOpenAddMunicipalityForm={isOpenAddMunicipalityForm}
                createNewMunicipality={createNewMunicipality}
                isCreatingMunicipality={isCreatingMunicipality}
                />
              )}
              <Box>
                {municipalities.length > 0 ? (municipalitiesTable()): <Text>Aún no se han agregado municipalidades</Text>}
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export default InitiativesList;