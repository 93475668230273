import { useEffect, useState, lazy, Suspense } from 'react';

// Supabase client
import { supabase } from './client';

// Axios
import axios from 'axios';

// Vercel analytics
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";

// Chakra-ui
import {
  Box,
  SimpleGrid,
  Flex,
  useMediaQuery,
  Image,
  Tooltip,
  Text
} from '@chakra-ui/react';

// Recoil
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

// ChakraProvider
import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeScript } from '@chakra-ui/react';
import theme from './theme';

// React router dom
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
  Navigate
} from "react-router-dom";

// Components
import MainHeaderNav from './components/NavBar';
import EventsList from './components/Dashboard/DashboardContent/EventsList';
import InitiativesList from './components/Dashboard/DashboardContent/InitiativesList';
import DashboardHome from './components/Dashboard/DashboardContent/DashboardHome';
import PostsList from './components/Dashboard/DashboardContent/PostsList';
import LandingNavbar from './components/NavBar/LandingNavbar';
import MainSideBar from './components/NavBar/MainSideBar';
import FooterNavBar from './components/NavBar/FooterNavbar';

// Views 
const SignUpView = lazy(() => import('./views/Signup/SignUpView'));
const LoginView = lazy(() => import('./views/Signin/LoginView'));
const AccountView = lazy(() => import('./views/Account/MyAccount/AccountView'));
const UserAccountView = lazy(() => import('./views/Account/UserAccount/UserAccountView'));
const WelcomeHomeView = lazy(() => import('./views/Home/WelcomeHomeView'));
const PostsView = lazy(() => import('./views/Post/PostsView'));
const CreatePostView = lazy(() => import('./views/Post/CreatePostView'));
const SinglePostView = lazy(() => import('./views/Post/SinglePostView'));
const SinglePostEditView = lazy(() => import('./views/Post/SinglePostEditView'));
const EventsView = lazy(() => import('./views/Event/EventsView'));
const SingleEventView = lazy(() => import('./views/Event/SingleEventView'));
const SingleEventEditView = lazy(() => import('./views/Event/SingleEventEditView'));
const CreateEventView = lazy(() => import('./views/Event/CreateEventView'));
const CreateInitiativeView = lazy(() => import('./views/Initiative/CreateInitiativeView'));
const SingleInitiativeView = lazy(() => import('./views/Initiative/SingleInitiativeView'));
const SingleInitiativeEditView = lazy(() => import('./views/Initiative/SingleInitiativeEditView'));
const InitiativesView = lazy(() => import('./views/Initiative/InitiativesView'));
const AccountSettingsView = lazy(() => import('./views/AccountSettings/AccountSettingsView'));
const PaymentResultView = lazy(() => import('./views/Payments/paymentResultView'));
const DashboardView = lazy(() => import('./views/Dashboard/AdminDashboard/DashboardView'));
const UserDashboardView = lazy(() => import('./views/Dashboard/UserDashboard/UserDashboardView'));
const LandingHome = lazy(() => import('./views/LandingPage/LandingHome/LandingHomeView'));
const PasswordRecoveryFormView = lazy(() => import('./views/Password/PasswordRecoveryForm/PasswordRecoveryFormView'));
const ResetPasswordView = lazy(() => import('./views/Password/ResetPassword/ResetPasswordView'));
const PrivacyPolicyView = lazy(() => import('./views/PrivacyPolicy/PrivacyPolicyView'));
const BeforeSignUpView = lazy(() => import('./views/BeforeSignUp/BeforeSignUpView'));
const SignUpAsOrganizationView = lazy(() => import('./views/SignUpAsOrganization/SignUpAsOrganizationView'));
const AboutView = lazy(() => import('./views/About/AboutView'));
const NotificationsView = lazy(() => import('./views/Notifications/NotificationsView'));
const FaqView = lazy(() => import('./views/Faq/FaqView'));
const NoMatchView = lazy(() => ('./views/NoMatch/NoMatchView'));
const EventPaymentResultView = lazy(() => import('./views/Payments/EventPaymentResult/eventPaymentResultView'));
const CreateEnvironmentalAlertView = lazy(() => import('./views/EnvironmentalAlert/CreateEnvironmentalAlertView'));
const EnvironmentalAlertMapView = lazy(() => import('./views/EnvironmentalAlert/EnvironmentAlertMapView'));
const CreatePickupRequestView = lazy(() => import('./views/PickupRequest/CreatePickupRequestView'));
const MyRewardsAvailableView = lazy(() => import('./views/Rewards/MyRewardsAvailableView'));

// Images && Icons
const alertIcon = require('../src/assets/images/side-bar-icons/alert-square.png');

const App = (props) => {
  const [session, setSession] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [userRole, setUserRole] = useState(null);
  const [locationName, setLocationName] = useState(null);
  
  useEffect(() => {
    setSession(supabase.auth.session());
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if(session) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    })
    getUserRole();
    navigator.geolocation.getCurrentPosition(getPosition);
  }, []);

  const MainNavigationDisplay = () => {
    let location = useLocation();
    const isDashboard = location.pathname.split('/').includes('dashboard');
    const isResetPassword = location.pathname.split('/').includes('reset-password');
    const isCreateViews = location.pathname.split('/').includes('create');
    const isEditViews = location.pathname.split('/').includes('edit');
    const isInitiativeView = location.pathname.split('/').includes('initiatives');
    const isPostView = location.pathname.split('/').includes('posts');
    const isEventView = location.pathname.split('/').includes('events');
    const paymentResultView = location.pathname.split('/').includes('payment-result');
    const eventPaymentResultView = location.pathname.split('/').includes('event-payment-result');
    
    if(!isDashboard 
      && !isResetPassword 
      && !isCreateViews 
      && !isEditViews 
      && !isInitiativeView 
      && !isPostView
      && !paymentResultView
      && !eventPaymentResultView
      ) {
      return <MainHeaderNav session={session}/>;
    } 
  }

  const DisplayMainSideBar = () => {
    let location = useLocation();
    const isHomeLanding = location.pathname.split('/').includes('home-landing');
    const isInitiativeView = location.pathname.split('/').includes('initiatives');
    if(!isHomeLanding) {
     return (
      <Box h='auto' w={'72px'}>
        <MainSideBar />
      </Box>
      );
    } else {
      return;
    }
  }

  const getUserRole = async() => {
    const userId = supabase.auth.user() && Object.keys(supabase.auth.user()).length > 0 ? supabase.auth.user().id : '';
   
    try {
      const { data } = await supabase
      .from('user_roles')
      .select('role_id')
      .eq('user_id', userId)
      if(data && data.length > 0) {
        setUserRole(data[0].role_id);
      }
    } catch (err) {
      console.log('There was an error retrieving the user role ', err);
    }
  }

  const getPosition = (position) => {
    const {
      latitude,
      longitude
    } = position.coords;
    getThePlaceName(position.coords);
  }

  const getThePlaceName = async(coords) => {
    const { latitude, longitude } = coords;
    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAP_BOX_TOKEN}`;
      const response = await fetch(endpoint);
      const result = await response.json();
      setLocationName(result?.features[3].text);
    } catch (err) {
      console.log('There was an error getting place name', err.message);
    }
  }

  const loadingMessage = () => {
    return(
      <Flex justifyContent={'center'} mt='20%'>
        <Text color='#665' fontSize={'20px'} fontWeight={'600'} lineHeight={'14px'}>
          Cargando...
        </Text>
      </Flex>
    )
  }

  return (
    <>
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <SpeedInsights/>
        <BrowserRouter>
        <Suspense fallback={loadingMessage()}>
          <Box maxW='1280px' position={'relative'} h='100%' margin='0 auto' sx={{borderRight: '1px solid #DEEFF0', borderLeft: '1px solid #DEEFF0'}}>
            <Flex>
              {session && !isSmallerScreen && (
                <DisplayMainSideBar />
              )}
              <Box w='100%'>
                {session && (<MainNavigationDisplay />)}
                <Routes>
                  {(!session) ? (
                    <>
                    <Route path="/" element={<LandingHome />}/>
                    <Route path="/signup" element={<SignUpView session={session}/>} />
                    <Route path="/login" element={<LoginView />} />
                    <Route path="/password-recovery" element={<PasswordRecoveryFormView />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
                    <Route path="/before-signup" element={<BeforeSignUpView />} />
                    <Route path="/singup-organization" element={<SignUpAsOrganizationView />} />
                    <Route path="/about" element={<AboutView />} />
                    {/* <Route path="*" element={<NoMatchView />} /> */}
                    </>
                  ):(
                    <>
                    <Route path="/" element={<WelcomeHomeView/>} />
                    <Route path="/posts" element={<PostsView/>} />
                    <Route path="/my-account" element={<AccountView session={session}/>} />
                    <Route path="/user-account" element={<UserAccountView session={session}/>} />
                    <Route path="/posts/create" element={<CreatePostView session={session}/>} />
                    <Route path="/posts/:id" element={<SinglePostView session={session} />} />
                    <Route path="/posts/:id/edit" element={<SinglePostEditView session={session} />} />
                    <Route path="/events" element={<EventsView session={session}/>} />
                    <Route path="/events/:id" element={<SingleEventView session={session} />} />
                    <Route path="/create-comunnity" element={<SingleEventView session={session} />} />
                    <Route path="/events/:id/edit" element={<SingleEventEditView session={session} />} />
                    <Route path="/events/create" element={<CreateEventView session={session} />} />
                    <Route path="/initiatives" element={<InitiativesView session={session} />} />
                    <Route path="/initiatives/create" element={<CreateInitiativeView session={session} />} />
                    <Route path="/initiatives/:id" element={<SingleInitiativeView session={session} />} />
                    <Route path="/initiatives/:id/edit" element={<SingleInitiativeEditView session={session} />} />
                    <Route path="/settings" element={<AccountSettingsView session={session} />} />
                    <Route path="/payment-result" element={<PaymentResultView session={session}/>} />
                    <Route path="/notifications" element={<NotificationsView session={session}/>} />
                    <Route path="/faq" element={<FaqView session={session}/>} />
                    <Route path="/admin-dashboard" element={<DashboardView session={session}/>} />
                    {/* <Route path="/admin-dashboard" element={<ProtectedRoute userRoleId={userRole} session={session}/>}> */}
                      {/* <Route exact path="dashboardhome" element={<DashboardHome />}/>
                      <Route exact path="eventslist" element={<EventsList />}/>
                      <Route exact path="initiativeslist" element={<InitiativesList />}/>
                      <Route exact path="postslist" element={<PostsList />}/> */}
                    {/* </Route> */}
                    <Route path="/my-dashboard" element={<UserDashboardView session={session} />} />
                    <Route path="/reset-password" element={<ResetPasswordView />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
                    <Route path="/event-payment-result" element={<EventPaymentResultView />} />
                    <Route path="/environmental-alert/create" element={<CreateEnvironmentalAlertView />} />
                    <Route path="/environmental-alert-map" element={<EnvironmentalAlertMapView />} />
                    <Route path="/pickup-request/create" element={<CreatePickupRequestView />} />
                    <Route path="/my-rewards-available" element={<MyRewardsAvailableView />} />
                    <Route path="*" element={<NoMatchView />} />
                    </>
                  )}
                 
                </Routes>
                {session && isSmallerScreen && <FooterNavBar />}
              </Box>
            </Flex>
          </Box>
        </Suspense>
        </BrowserRouter>
      </ChakraProvider>
      <Analytics />
    </RecoilRoot>
    </>
  );
}

export default App;
