// React
import React, { useEffect, useState } from 'react';

// React router dom
import { useNavigate } from 'react-router-dom';

// Chakra-ui
import {
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Image,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Flex,
  useToast,
  Button,
  useMediaQuery
} from '@chakra-ui/react';

// Atom
import { useRecoilValue, useRecoilState } from "recoil";
import { userScoreAtom } from '../../../../recoil/atom/UserScore';

// Client
import { supabase } from '../../../../client';

// Styles
import './style.sass';

// Images
const deleteIcon = require('../../../../assets/images/crud_icons/delete-icon.png');
const editIcon = require('../../../../assets/images/crud_icons/edit-icon.png');
const seeIcon = require('../../../../assets/images/crud_icons/eyes-icon.png');

const PostsList = () => {
  const navigate = useNavigate();
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [posts, setPosts] = useState([]);
  const [atomUserScore, setAtomUserScore] = useRecoilState(userScoreAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pagesPassed, setPagesPassed] = useState(1);
  const [userRole, setUserRole] = useState(null);
  const toast = useToast();

  useEffect(() => {
    getPosts();
    getUserRole();
  }, []);

  const getPosts = async() => {
    try {
      const { data } = await supabase
      .from('posts')
      .select(`
      id,
      title,
      images
      `)
      if(data.length > 0) {
        setPosts(data);
        let pagesQty = Math.ceil(data.length / itemsPerPage);
        if(pagesQty < 2) {
          setIsNextButtonDisabled(true);
        }
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (err) {
      console.log('error ', err);
    }
  } 

  const getUserRole = async() => {
    const userId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('user_roles')
      .select('role_id')
      .eq('user_id', userId)
      setUserRole(data[0].role_id);
    } catch (err) {
      console.log('There was an error retrieving the user role ', err);
    }
  }
  
  const displaySubsetData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = posts.slice(startIndex, endIndex);
    return subset;
  }

  const prevPage = () => {
    if(currentPage === 1) {
      setIsPrevButtonDisabled(true);
      setIsNextButtonDisabled(false);
    }
    setCurrentPage(currentPage - 1);
  }

  const nextPage = () => {
    if(pagesPassed + 1 === totalPages) {
      setIsNextButtonDisabled(true);
    }
    setCurrentPage(currentPage + 1); 
    setPagesPassed(pagesPassed + 1);
    setIsPrevButtonDisabled(false);
  }

  const redirectTo = (type, id) => {
    switch (type) {
      case 'watch':
        navigate(`/posts/${id}`);
        break;

      case 'edit':
        navigate(`/posts/${id}/edit`);
        break;
    
      default:
        break;
    }
  }

  const postDeletedToaster = () => {
    return(
      toast({
        title: 'Accion eliminada',
        description: "Has eliminado una acción de un usuario.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const deletePostCategoryRelation = async(id) => {
    try {
      const { data } = await supabase
      .from('post_categories')
      .delete()
      .eq('post_id', id)
    } catch (err) {
      console.error('There was an error deleting the category relation for post', err);
    }
  }

  const updateUserScore = async() => {
    const userId = await supabase.auth.user().id;
    const {data} = await supabase.from('user_scores').select('score').eq('user_id', userId);

    if(data && data[0].score > 0) {
      let newScore = data[0].score - 10;
      try {
        const { data } = await supabase
        .from('user_scores')
        .update({ score: newScore })
        .eq('user_id', userId)

        setAtomUserScore(newScore);
      } catch (err) {
        console.error('There was an error updating the user score ', err);
      }
    }
  }

  const deleteSavedImagesFromBucket = async(images) => {
    try {
      images.forEach(async(image) => {
        const { data } = await supabase
        .storage
        .from('posts-images')
        .remove([`post_images/${image}`])
      })
    } catch (err) {
      console.log('There was an error deleting the saved images from the bucket', err);
    }
  }

  const deleteNotificationForPost = async(id) => {
    try {
      const { data } = await supabase
      .from('notifications')
      .delete()
      .eq('notification_link', `/posts/${id}`)
    } catch (err) {
      console.log('There was an error deleting the notification for post', err);
    }
  }

  const deletePost = async(images, id) => {
    if(userRole === 4) {
      try {
        await supabase
        .from('posts')
        .delete()
        .eq('id', id);
  
        const newPosts = posts.filter((item) => item.id !== id);
        setPosts(newPosts);
        deletePostCategoryRelation(id);
        updateUserScore();
        postDeletedToaster();
        deleteSavedImagesFromBucket(images);
        deleteNotificationForPost(id);
      } catch (err) {
        console.log('There was an error deleting the post', err);
      }
    } else {
      console.log('Only superadmin role can delete posts from dashboard');
    }
  }

  const postsListTable = () => {
    return(
      <Box pb='20px' w={'90%'}>
        <TableContainer w={'100%'} borderRadius={'10px'} border='1px solid #DEEFF0' boxShadow={'0px 5px 14px 0px #E4E8EE'}>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Titulo de la acción</Th>
                <Th isNumeric></Th>
              </Tr>
            </Thead>
            <Tbody w='100%'>
              {displaySubsetData().map((post, ix) => {
                return(

                  <Tr key={ix}>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{post.id}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{post.title}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} isNumeric>
                    <Flex gap={isSmallerScreen ? '7px' : '10px'} justify='end'>
                      <Box cursor='pointer'>
                        <Image src={seeIcon} onClick={() => redirectTo('watch', post.id)} h={'20px'} w={'20px'} />
                      </Box>
                      {/* <Box cursor='pointer'>
                        <Image src={editIcon} onClick={() => redirectTo('edit', post.id)} h={'20px'} w={'20px'} />
                      </Box>
                      <Box cursor='pointer'>
                        <Image src={deleteIcon} onClick={() => deletePost(post.images, post.id)} h={'20px'} w={'20px'} />
                      </Box> */}
                    </Flex>
                  </Td>
                  </Tr>
                  
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box> 
    )
  }

  return(
    <Box className="post-list-container">
      <Box ml={5} mt={7}>
        <Stack spacing={'12px'}>
          <Box className="post-list-title">
            <Flex justifyContent={'space-between'} w='90%' alignItems={'center'}>
              <Text color={'#000'} fontSize={'20px'} fontWeight='400' lineHeight={'140%'} letterSpacing='0.15px'>Acciones de usuarios</Text>
              <Box>
                <Flex justifyContent={'center'} gap='16px' mt='10px'>
                  <Button isDisabled={isPrevButtonDisabled} bg='#FFF' h={isSmallerScreen ? '32px' : '42px'} borderRadius={'100px'} border='1px solid #007472' onClick={() => prevPage()}>
                    <Flex alignItems={'center'} justifyContent='center' h='100%'>
                      <Text fontSize={'18px'} color='#007472' fontWeight='700' lineHeight={'140%'} letterSpacing='0.15px'>{`<`}</Text>
                    </Flex>
                  </Button>
                  <Button isDisabled={isNextButtonDisabled} bg='#FFF' h={isSmallerScreen ? '32px' : '42px'} borderRadius={'100px'} border='1px solid #007472' onClick={() => nextPage()}>
                    <Flex alignItems={'center'} justifyContent='center' h='100%'>
                      <Text fontSize={'18px'} color='#007472' fontWeight='700' lineHeight={'140%'} letterSpacing='0.15px'>{`>`}</Text>
                    </Flex>
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
          {(posts && posts.length > 0) ? (
            postsListTable()
          ) : (
            <Text color={'#000'} fontSize={'20px'} pl={isSmallerScreen ? '40px' : ''} fontWeight='400' lineHeight={'140%'} letterSpacing='0.15px'>
              No hay acciones subidas aún
            </Text>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default PostsList;