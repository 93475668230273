// Fonts imported
import '@fontsource-variable/montserrat';

// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

// 3. extend the theme
const theme = extendTheme({ config, fonts: {
  body: 'Montserrat Variable, sans-serif'
}
})

export default theme