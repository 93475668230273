// React
import React, {useState, useEffect} from 'react';

// Chakra-ui
import {
    Box,
    Flex,
    Stack,
    Image,
    Text,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
} from '@chakra-ui/react';

// Chakra-ui-icons
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

// React-router-dom
import { useNavigate } from 'react-router-dom';

// Supabase client
import { supabase } from '../../../client';

// Images & icons
const brandLogoIcon = require('../../../assets/images/brand_logo/brand-logo-icon.png');
const homeIcon = require('../../../assets/images/side-bar-icons/home.png');
const dashboardIcon = require('../../../assets/images/side-bar-icons/dashboard-responsive.png');
const eventsIcon = require('../../../assets/images/side-bar-icons/event-responsive.png');
const configIcon = require('../../../assets/images/side-bar-icons/config.png');
const questionsIcon = require('../../../assets/images/side-bar-icons/questions.png');
const moreIcon = require('../../../assets/images/side-bar-icons/menu.png');
const logoutIcon = require('../../../assets/images/side-bar-icons/logout.png');
const environmentalIcon = require('../../../assets/images/side-bar-icons/alert-square.png');
const carIcon = require('../../../assets/images/navbar-icons/car.png');

const FooterNavBar = () => {
  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState('bottom');
  const [activeLocation, setActiveLocation] = useState(false);
  const [location, setLocation] = useState('');

  useEffect(() => {
    activeNavigationItem();
  }, []);

  const logOut = async() => {
      const { error } = await supabase.auth.signOut();
      if(error) throw error;
      onClose();
      navigate('/');
  }

  const openMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    onOpen();
  }

  const closeMenu = () => {
    onClose();
  }

  const redirect = (destination) => {
    navigate(destination);
    activeNavigationItem(destination);
    onClose();
  }
  
  const activeNavigationItem = (location) => {
    setLocation(location);
  }

  const goToDashboard = async () => {
    const currentUserId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('user_roles')
      .select('role_id')
      .eq('user_id', currentUserId)
      if(data && data.length > 0) {
        if(data[0].role_id === 4) {
          navigate('/admin-dashboard');
        } else {
          navigate('/my-dashboard');
        }
      }
    } catch (err) {
      console.log('There was an error trying to get user user role ', err);
    }
  }

  return (
    <Box mt='100px' zIndex={4}>

    <Drawer placement={placement} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg={'#007472'}>
        <DrawerHeader borderBottomWidth='1px'>
        <Box w='100%'>
          <Flex justifyContent={'end'} alignItems='center'>
            <CloseIcon cursor={'pointer'} onClick={() => closeMenu()} h='15px' w='15px' color='#FFF'/>
          </Flex>
        </Box>
        </DrawerHeader>
        <DrawerBody pt='20px'>
        <Box cursor={'pointer'} onClick={() => goToDashboard()}>
          <Flex alignItems={'center'} pl='8px' gap='8px' mb='8px'>
            <Image cursor={'pointer'} h='24px' w='23px' src={dashboardIcon} />
            <Text fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Estadisticas</Text>
          </Flex>
        </Box>
        <Box cursor={'pointer'} onClick={() => navigate('my-account')}>
          <Flex alignItems={'center'}>
            <Image cursor={'pointer'} h='40px' w='40px' src={configIcon} />
            <Text fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Configuración</Text>
          </Flex>
        </Box>
        <Box cursor={'pointer'} onClick={() => navigate('/faq')}>
          <Flex alignItems={'center'}>
            <Image cursor={'pointer'} h='40px' w='40px' src={questionsIcon} />
            <Text fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>FAQ</Text>
          </Flex>
        </Box>
        <Box cursor={'pointer'} onClick={() => logOut()}>
          <Flex alignItems={'center'}>
            <Image cursor={'pointer'} h='40px' w='40px' src={logoutIcon} />
            <Text fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Salir</Text>
          </Flex>
        </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  
    <Box
    w='100%'
    bg={'#007472'}
    pb='14px'
    position={'fixed'}
    bottom={'0'}
    zIndex={5}
    >
      <Flex gap='46px' alignItems={'center'} justifyContent='center'>
        <Box display={'flex'} alignItems='center' flexDir={'column'}>
          <Box borderTop={location === 'home' && '4.5px solid rgba(255, 255, 255, 0.50)'} h='10.5px' w='45px'></Box>
          <Image cursor={'pointer'} onClick={() => navigate('/')} src={homeIcon} h='24px' w='24px'/>
          <Text mt='5px' fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Inicio</Text>
        </Box>
{/* 
        <Box display={'flex'} alignItems='center' flexDir={'column'} onClick={() => navigate('/pickup-request/create')}>
          <Box borderTop={location === '' && '4.5px solid rgba(255, 255, 255, 0.50)'} h='10.5px' w='45px'></Box>
          <Image cursor={'pointer'} src={carIcon} h='25px' w='27px'/>
          <Text mt='5px' fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Agendar</Text>
        </Box> */}

        <Box display={'flex'} alignItems='center' flexDir={'column'} onClick={() => navigate('/events')}>
          <Box borderTop={location === '' && '4.5px solid rgba(255, 255, 255, 0.50)'} h='10.5px' w='45px'></Box>
          <Image cursor={'pointer'} src={eventsIcon} h='24px' w='24px'/>
          <Text mt='5px' fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Eventos</Text>
        </Box>

        <Box display={'flex'} alignItems='center' flexDir={'column'} onClick={() => navigate('/environmental-alert-map')}>
          <Box borderTop={location === '' && '4.5px solid rgba(255, 255, 255, 0.50)'} h='10.5px' w='45px'></Box>
          <Image cursor={'pointer'} src={environmentalIcon} h='24px' w='24px'/>
          <Text mt='5px' fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Reportar</Text>
        </Box>

        <Box onClick={() => openMenu()} display={'flex'} alignItems='center' flexDir={'column'}>
          <Box h='10.5px' w='45px'></Box>
          <Box>
            <HamburgerIcon cursor={'pointer'} h='21px' w='21px' color='#FFF' />
          </Box>
          <Text mt='5px' fontSize={'12px'} fontWeight={'400'} lineHeight={'166%'} letterSpacing={'0.2px'} color={'#FFF'}>Mas</Text>
        </Box>

      </Flex>
      
    </Box>
    </Box>
  )
}

export default FooterNavBar;