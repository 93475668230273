// React
import React, { useEffect, useState } from 'react';

// React router dom
import { useNavigate } from 'react-router-dom';

// Components
import SelfieModal from './components/SelfieModal';

// Chakra-ui
import {
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Image,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Flex,
  useToast,
  Button,
  useMediaQuery,
  Switch
} from '@chakra-ui/react';

// Axios
import axios from 'axios';

// Atom
import { useRecoilValue, useRecoilState } from "recoil";
import { userScoreAtom } from '../../../../recoil/atom/UserScore';

// Client
import { supabase } from '../../../../client';

// Images
const deleteIcon = require('../../../../assets/images/crud_icons/delete-icon.png');
const editIcon = require('../../../../assets/images/crud_icons/edit-icon.png');
const seeIcon = require('../../../../assets/images/crud_icons/eyes-icon.png');

const VerificationRequestsList = () => {
  const navigate = useNavigate();
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [verificationRequests, setVerificationRequests] = useState([]);
  const [atomUserScore, setAtomUserScore] = useRecoilState(userScoreAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pagesPassed, setPagesPassed] = useState(1);
  const [isVerificationApproved, setIsVerificationApproved] = useState(false);
  const [isVerificationRejected, setIsVerificationRejected] = useState(false);
  const [isOpenSelfieModal, setIsOpenSelfieModal] = useState(false);
  const [selfieUrl, setSelfieUrl] = useState(null);
  const toast = useToast();

  useEffect(() => {
    getVerificationRequests();
  }, []);

  const getVerificationRequests = async() => {
    try {
      const { data } = await supabase
      .from('verification_requests')
      .select('*')
      if(data.length > 0) {
        setVerificationRequests(data);
        let pagesQty = Math.ceil(data.length / itemsPerPage);
        if(pagesQty < 2) {
          setIsNextButtonDisabled(true);
        }
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (err) {
      console.log('There was an error getting verification approvals ', err);
    }
  } 

  const displaySubsetData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = verificationRequests.slice(startIndex, endIndex);
    return subset;
  }

  const prevPage = () => {
    if(currentPage === 1) {
      setIsPrevButtonDisabled(true);
      setIsNextButtonDisabled(false);
    }
    setCurrentPage(currentPage - 1);
  }

  const nextPage = () => {
    if(pagesPassed + 1 === totalPages) {
      setIsNextButtonDisabled(true);
    }
    setCurrentPage(currentPage + 1); 
    setPagesPassed(pagesPassed + 1);
    setIsPrevButtonDisabled(false);
  }

  const approvedVerificationToast = () => {
    return(
      toast({
        title: 'Verificación Aprobada',
        description: "Se ha aprobado la verificación.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const updateVerificationRequestColumnStatusToApproved = async(requestId) => {
    try {
      const { data } = await supabase
      .from('verification_requests')
      .update({verification_status: 'approved'})
      .eq('id', requestId)
      if(data && data.length > 0) {
        approvedVerificationToast();
      }
    } catch (err) {
      console.log('There was an error updating verification status column ', err);
    }
  }

  const approvedVerification = async(userId, requestId) => {
    try {
      const { data } = await supabase
      .from('profiles')
      .update({is_verified: true})
      .eq('id', userId)
      if(data && data.length > 0) {
        setIsVerificationApproved(true);
        updateVerificationRequestColumnStatusToApproved(requestId);
        approvedVerificationNotificationEmail(userId);
      }
    } catch (err) {
      console.log('There was an error updating profile column is verified ', err);
    }
  }

  const updateVerificationRequestColumnStatusToRejected = async(requestId) => {
    try {
      const { data } = await supabase
      .from('verification_requests')
      .update({verification_status: 'rejected'})
      .eq('id', requestId)
    } catch (err) {
      console.log('There was an error updating verification status column ', err);
    }
  }

  const rejectedVerification = async(userId, requestId) => {
    try {
      const { data } = await supabase
      .from('profiles')
      .update({is_verified: false})
      .eq('id', userId)
      if(data && data.length > 0) {
        setIsVerificationRejected(true);
        updateVerificationRequestColumnStatusToRejected(requestId);
        rejectedVerificationNotificationEmail(userId);
      }
    } catch (err) {
      console.log('There was an error updating profile column is verified ', err);
    }
  }

  const getEventOwnerEmail = async(userId) => {
    try {
      const { data:user } = await supabase.rpc('get_email_from_auth_users', {user_id: userId})
      if(user && user.length > 0) {
        return user[0].email;
      }
    } catch (err) {
      console.log('The has been an error getting post owner email', err);
    }
  }

  const approvedVerificationNotificationEmail = async(userId) => {
    const userEmail = await getEventOwnerEmail(userId);
    const userInfo = {
      email: userEmail
    }

    try {
      const emailApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NODE_LOCAL_API_URL : process.env.REACT_APP_NODE_PRODUCTION_API_URL;
      axios({
        method: 'post',
        url: `${emailApiUrl}/account-verification-approved-email`,
        data: {userInfo}
      });
    } catch (err) {
      console.log('There was an error sending the email to the user', err);
    }
  }

  const rejectedVerificationNotificationEmail = async(userId) => {
    const userEmail = await getEventOwnerEmail(userId);
    const userInfo = {
      email: userEmail
    }

    try {
      const emailApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NODE_LOCAL_API_URL : process.env.REACT_APP_NODE_PRODUCTION_API_URL;
      axios({
        method: 'post',
        url: `${emailApiUrl}/account-verification-rejected-email`,
        data: {userInfo}
      });
    } catch (err) {
      console.log('There was an error sending the email to the user', err);
    }
  }

  const displayProcess = (status) => {
    if(status === 'approved' || isVerificationApproved === true) {
      return 'Aprobado';
    }
    if(status === 'rejected' || isVerificationRejected === true) {
      return 'Rechazado';
    }
    
    return 'Pendiente';
  }

  const openSelfieModal = (selfieUrl) => {
    setIsOpenSelfieModal(true);
    setSelfieUrl(selfieUrl);
  }

  const closeSelfieModal = () => {
    setIsOpenSelfieModal(false);
    setSelfieUrl(null);
  }

  const verificationRequestsTable = () => {
    return(
      <Box pb='20px' w={'90%'}>
        <TableContainer w={'100%'} borderRadius={'10px'} border='1px solid #DEEFF0' boxShadow={'0px 5px 14px 0px #E4E8EE'}>
          <Table>
            <Thead>
              <Tr>
                <Th>Nombre Usuario</Th>
                <Th>Telefono</Th>
                <Th>Email</Th>
                <Th>Instagram</Th>
                <Th>Website</Th>
                <Th>Foto Usuario</Th>
                <Th>Sobre el usuario</Th>
                <Th>Estado de la Verificación</Th>
                <Th>¿Se aprueba la verificación?</Th>
              </Tr>
            </Thead>
            <Tbody w='100%'>
              {displaySubsetData().map((element, ix) => {
                return(

                  <Tr key={ix}>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.name}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.phone_number}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.email}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.instagram_url}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.website_url}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} cursor={'pointer'} onClick={() => openSelfieModal(element.user_selfie)}>{element.user_selfie}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{element.about_you}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }}>{displayProcess(element.verification_status)}</Td>
                  <Td minW={{ sm: "150px", md: "200px", lg: "auto" }} isNumeric>
                    <Flex justifyContent={'center'} gap={isSmallerScreen ? '7px' : '10px'} justify='end'>
                      <Button 
                      bg={isVerificationApproved || element.verification_status === 'approved' ? '#007472' : 'none'} 
                      border='1px solid #007472' 
                      onClick={() => approvedVerification(element.user_id, element.id)}
                      >
                        <Text color={isVerificationApproved || element.verification_status === 'approved' ? '#FFF' : '#007472'}>Si</Text>
                      </Button>

                      <Button 
                      bg='none' 
                      border='1px solid #F1352B'
                      onClick={() => rejectedVerification(element.user_id, element.id)}
                      >
                        <Text color={'#F1352B'}>No</Text>
                      </Button>          
                    </Flex>
                  </Td>
                  </Tr>
                  
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box> 
    )
  }

  return(
    <Box className="post-list-container">
      <Box ml={5} mt={7}>
        <Stack spacing={'12px'}>
          <Box className="post-list-title">
            <Flex justifyContent={'space-between'} w='90%' alignItems={'center'}>
              <Text color={'#000'} fontSize={'20px'} fontWeight='400' lineHeight={'140%'} letterSpacing='0.15px'>Solicitud de verificaciones de perfil</Text>
              <Box>
                <Flex justifyContent={'center'} gap='16px' mt='10px'>
                  <Button isDisabled={isPrevButtonDisabled} bg='#FFF' h={isSmallerScreen ? '32px' : '42px'} borderRadius={'100px'} border='1px solid #007472' onClick={() => prevPage()}>
                    <Flex alignItems={'center'} justifyContent='center' h='100%'>
                      <Text fontSize={'18px'} color='#007472' fontWeight='700' lineHeight={'140%'} letterSpacing='0.15px'>{`<`}</Text>
                    </Flex>
                  </Button>
                  <Button isDisabled={isNextButtonDisabled} bg='#FFF' h={isSmallerScreen ? '32px' : '42px'} borderRadius={'100px'} border='1px solid #007472' onClick={() => nextPage()}>
                    <Flex alignItems={'center'} justifyContent='center' h='100%'>
                      <Text fontSize={'18px'} color='#007472' fontWeight='700' lineHeight={'140%'} letterSpacing='0.15px'>{`>`}</Text>
                    </Flex>
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
          {(verificationRequests && verificationRequests.length > 0) ? (
            verificationRequestsTable()
          ) : (
            <Text color={'#000'} fontSize={'16px'} pl={isSmallerScreen ? '40px' : ''} fontWeight='400' lineHeight={'140%'} letterSpacing='0.15px'>
              No hay verificaciones de perfil que hacer.
            </Text>
          )}
        </Stack>
      </Box>
      <SelfieModal
      isOpenSelfieModal={isOpenSelfieModal}
      closeSelfieModal={closeSelfieModal}
      selfieUrl={selfieUrl}
      />
    </Box>
  )
}

export default VerificationRequestsList;