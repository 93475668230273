// React
import React, { useEffect, useState } from 'react';

// Supabase client
import { supabase } from '../../client';

// React-router-dom
import { useNavigate } from 'react-router-dom';

// Chakra-ui
import {
  Box,
  Flex,
  Text,
  Image,
  useDisclosure,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton
} from '@chakra-ui/react';

// Images && Icons
const notificationIcon = require('../../assets/images/notification-icon/notification.png');
const asReadIcon = require('../../assets/images/notification-icon/outline.png');
const brandLogoIcon = require('../../assets/images/welcome-home/empty-image.png');

const Notifications = () => {
  const navigate = useNavigate();
  const [notificationNumber, setNotificationNumber] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  
  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async() => {
    const userId = supabase.auth.user().id;
    
    try {
      const { data } = await supabase
      .from('notifications')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })
      .limit(4)
      if(data && data.length > 0) {
        setNotifications(data);
      }
    } catch (err) {
      console.log('There was an error getting notifications ', err);
    }
  };

  const displayNotificationNumber = () => {
    let count = 0
    notifications.map((notification) => {
      if(notification.read === false) {
        count += 1;
      }
    })
    return count;
  }

  const selectedNotification = async(item, pos) => {
    try {
      const { data } = await supabase
      .from('notifications')
      .update({read: true})
      .eq('id', item.id)

      setNotificationNumber(notificationNumber - 1);
      // Get the notification item position and change the prop value to true
      notifications[pos].read = true;
      navigate(`${item.notification_link}`);
    } catch (err) {
      console.log('There was an error getting notifications ', err);
    }
    
  }

  const markAllAsRead = async() => {
    notifications.map(async(item, ix) => {
      if(item.read === false) {
        try {
          const { data } = await supabase
          .from('notifications')
          .update({read: true})
          .eq('id', item.id)
        } catch (err) {
          console.log('There was an error getting notifications ', err);
        }
        notifications[ix].read = true;
      }
    })
  }

  const dateFormat = (createdDate) => {
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(createdDate).toLocaleDateString('es-ES', dateOptions);
    const time = new Date(createdDate).toLocaleString().split(',')[1];
    const publishedDate = `${date} ${time}`;
    return publishedDate;
  }

  return(
    <Popover
    >
  
      <PopoverTrigger>
        <Button bg='transparent' colorScheme={'none'}>
         <Box position={'relative'}>

          <Image src={notificationIcon} cursor='pointer' h='24px' w='24px'/>
   
          <Box position={'absolute'} top='0' left='3'>
            <Box bg='#FF0C00' h='13px' borderRadius={'100px'}>
              <Text
              textAlign={'center'}
              fontSize={'9px'}
              fontWeight='400'
              lineHeight={'14px'}
              p='0px 3px'
              >{displayNotificationNumber()}</Text>
            </Box>
          </Box>
        </Box>
       </Button>
      </PopoverTrigger>

      <PopoverContent
      border='1px solid #FFF' 
      _focusVisible={false}
      _focus={{outline: 'none'}}
      isOpen={isOpen}
      onClose={onClose}
      maxW='440px' 
      p={isSmallerScreen ? '0px 3px' : '0px 16px'}
      bg='#FFF'
      cursor={'pointer'}
      borderRadius={5}
      boxShadow={'0px 5px 14px 0px #E4E8EE'}
      >
        <Box p='10px 8px'>
          <Flex justifyContent={'space-between'} alignItems='center'>
            <Text color='#1A1F36' fontSize={'14px'} fontWeight='400' lineHeight={'143%'} letterSpacing='0.15px'>Notificaciones</Text>
            <Box>
              <Flex alignItems={'center'} gap='4px' cursor={'pointer'} onClick={() => markAllAsRead()}>
                <Text color='#1A1F36' fontSize={'14px'} fontWeight='400' lineHeight={'143%'} letterSpacing='0.15px'>Marcar como leído</Text>
                <Image src={asReadIcon} h='16px' w='16px' />
              </Flex>
            </Box>
          </Flex>
        </Box>
        {notifications.map((item, ix) => {
          const userAvatar =  item.user_creator_avatar === null ? brandLogoIcon : `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SUPABASE_LOCAL_URL : process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${item.user_creator_avatar}`; 
          return(
          <PopoverBody pb='8px' pt='8px' onClick={() => selectedNotification(item, ix)}>
              <Flex gap='16px'>
                <Box position={'relative'} w='41px'>
                  <Box position={'absolute'} left='0px'>
                  {item.read === false && (<Box bg='#90CDF4' border='1px solid #4299E1' w='8px' h='8px' borderRadius={'100px'}></Box> )}
                  </Box>
                  
                  <Box h='32px' w='32px'>
                    <Image objectFit={'cover'} ml='2px' src={userAvatar} h='32px' w='32px' borderRadius={'100px'}/>
                  </Box>
                </Box>
  
                <Text w='400px' color='#1A1F36' fontSize={'14px'} fontWeight='400' lineHeight={'143%'} letterSpacing='0.15px'>
                <Text as='b' color='#1A1F36' fontSize={'14px'} lineHeight={'143%'} letterSpacing='0.15px'>{`${item.username_creator} `} 
                </Text>
                {`${item.content}`} <Text as='b' color='#1A1F36' fontSize={'14px'} lineHeight={'143%'} letterSpacing='0.15px'>{item.publication_title}</Text>
                </Text>
                
              </Flex>
              <Box pl='50px' mt='10px'>
                <Text color='#A5ACB8' fontSize={'14px'} fontWeight='400' lineHeight={'143%'} letterSpacing='0.15px'>{dateFormat(item.created_at)}</Text>
              </Box>
          </PopoverBody>
          )
        })}
 
        <Box p='10px 2px' cursor={'pointer'} onClick={() => navigate('/notifications')}>
          <Text color='#1A1F36' textAlign={'center'} fontSize={'14px'} lineHeight={'143%'} letterSpacing='0.15px'>Ver más notificaciones</Text>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default Notifications;