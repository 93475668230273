import React, {useState, useEffect} from 'react';

// Supabase client
import { supabase } from '../../client';

// React-router-dom
import { useNavigate, Link as LinkRouter, useLocation } from 'react-router-dom';

// Atom
import { useRecoilState, useRecoilValue } from "recoil";
import { userScoreAtom } from '../../recoil/atom/UserScore';

// Components
import AvatarMenu from '../AvatarMenu';
import CreateModal from '../CreateModal';
import Notifications from '../Notifications';

import {
  Box,
  Flex,
  Text,
  Button,
  HStack, 
  Image,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';

// Images && Icons
const notificationIcon = require('../../assets/images/notification-icon/notification.png');
const homeIcon = require('../../assets/images/navbar-icons/home.png');
const profileIcon = require('../../assets/images/navbar-icons/profile.png');
const statsIcon = require('../../assets/images/navbar-icons/stats.png');
const addIcon = require('../../assets/images/navbar-icons/addmore.png');
const eventsIcon = require('../../assets/images/navbar-icons/events.png');
const faqIcon = require('../../assets/images/navbar-icons/faq.png');
const mapIcon = require('../../assets/images/navbar-icons/environmental-map.png');
const giftIcon = require('../../assets/images/navbar-icons/gift.png');

const MainHeaderNav = ({session}) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const [isSmallerScreen] = useMediaQuery('(max-width: 500px)');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userScore, setUserScore] = useRecoilState(userScoreAtom);
  const [isUserVerified, setIsUserVerified] = useState(false);

  useEffect(() => {
    if(session) {
      getUserScore();
    }
  }, [session]);

  const getUserScore = async() => {
    const userId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('user_scores')
      .select('score')
      .eq('user_id', userId)
      if(data && data.length > 0) {
        setUserScore(data[0].score);
      } else {
        setUserScore(0);
      }
    } catch (err) {
      console.log('Error fetching user score');
    }
  }

  const checkUserVerificationAccount = async() => {
    const currentUserId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('profiles')
      .select('is_verified')
      .eq('id', currentUserId)
      if(data && data.length > 0) {
        setIsUserVerified(data[0].is_verified);
      }
    } catch (err) {
      console.log('Error fetching user verification ', err);
    }
  }

  const openCreateModal = () => {
    setIsOpenModal(true);
    checkUserVerificationAccount();
  }

  const optionButtonCreateSelected = () => {
    setIsOpenModal(false);
  }

  const closeCreateModal = () => {
    setIsOpenModal(false);
  }

  const DisplayHeaderNavbarLocationNameAndIcon = () => {
    let location = useLocation();
    const home = location.pathname.split('/').includes('');
    const myAccount = location.pathname.split('/').includes('my-account');
    const userAccount = location.pathname.split('/').includes('user-account');
    const events = location.pathname.split('/').includes('events');
    const notifications = location.pathname.split('/').includes('notifications');
    const faq = location.pathname.split('/').includes('faq');
    const stats = location.pathname.split('/').includes('my-dashboard');
    const adminStats = location.pathname.split('/').includes('admin-dashboard');
    const environMentalAlert = location.pathname.split('/').includes('environmental-alert-map');
    const rewardsAvailable = location.pathname.split('/').includes('my-rewards-available');

    // if(home) {
    //   return (
    //     <Box>
    //       <HStack spacing={'24px'}>
    //         <Box>
    //           <Image src={homeIcon} h='24px' w='24px'/>
    //         </Box>
    //         <Box>
    //           <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Inicio</Text>
    //         </Box>
    //       </HStack>
    //     </Box>
    //   )
    // }

    if(myAccount) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={profileIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Perfil</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(userAccount) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={profileIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Perfil de usuario</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(events) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={eventsIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Eventos</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(notifications) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={notificationIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Notificaciones</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(faq) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={faqIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Centro de ayuda</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(stats) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={statsIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Estadísticas</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(adminStats) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={statsIcon} h='24px' w='24px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Estadísticas</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(environMentalAlert) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={mapIcon} h='30px' w='30px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Microbasurales</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    if(rewardsAvailable) {
      return (
        <Box>
          <HStack spacing={'24px'}>
            <Box>
              <Image src={giftIcon} h='30px' w='30px'/>
            </Box>
            <Box>
              <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Recompensas</Text>
            </Box>
          </HStack>
        </Box>
      )
    }

    return (
      <Box>
        <HStack spacing={'24px'}>
          <Box>
            <Image src={homeIcon} h='24px' w='24px'/>
          </Box>
          <Box>
            <Text color='#007472' fontSize={'20px'} fontWeight='600' lineHeight={'140%'} letterSpacing='0.15px'>Inicio</Text>
          </Box>
        </HStack>
      </Box>
    )
  }

  return (
    <Box>
      <Box
        bg={'#FFF'}
        color={'#FFF'}
        h={isSmallerScreen ? '72px' : '82px'}
        p={isSmallerScreen ? '16px' : '20px 40px'}
        sx={{borderBottom: '1px solid #DEEFF0'}}
        >

       <Box w='100%' bg='#FFF' display={'flex'} justifyContent='end' alignItems={'center'}>
        {!isSmallerScreen && 
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <DisplayHeaderNavbarLocationNameAndIcon />
          </Flex>
          }
          <HStack spacing={isSmallerScreen ? '16px' : '24px'}>
            {/* {!isSmallerScreen && <Text fontWeight='600'>{`${userScore} puntos`}</Text>} */}
            {isSmallerScreen ? (
              // <Image src={addIcon} cursor='pointer' onClick={() => openCreateModal()} h='41px' w='41px'/>
              <Button 
              color='white' 
              bg='#F1352B' 
              h={'30px'} 
              _hover={'none'} 
              borderRadius={'100px'}
              p='8px 12px'
              w={'100px'}
              onClick={() => openCreateModal()}>
                <Text
                fontSize={'16px'}
                fontWeight='700'
                lineHeight={'150%'}
                letterSpacing={'0.15px'}
                >Publicar</Text>
              </Button>
            ):(
              <Button 
              color='white' 
              bg='#F1352B' 
              h={'42px'} 
              _hover={'none'} 
              borderRadius={'100px'}
              p='8px 24px'
              w={'172px'}
              onClick={() => openCreateModal()}>
                <Text
                fontSize={'16px'}
                fontWeight='700'
                lineHeight={'150%'}
                letterSpacing={'0.15px'}
                >Publicar ahora</Text>
              </Button>
            )}
            <Notifications />
            <AvatarMenu />
          </HStack>
       </Box>
      </Box>

      <CreateModal 
        closeCreateModal={closeCreateModal} 
        optionButtonCreateSelected={optionButtonCreateSelected} 
        isOpen={isOpenModal} 
        onOpen={onOpen} 
        onClose={onClose}
        isUserVerified={isUserVerified}
      />
    </Box>
  );
}

export default MainHeaderNav;