// React
import React, { useState } from 'react';

// Utils
import regions from '../../../../../../utils/regiones/regiones.json';

// Chakra-ui
import {
  Text,
  ScaleFade,
  Input,
  Select,
  Box,
  Stack,
  FormControl,
  Button,
  Spinner
} from '@chakra-ui/react';

const CreateMunicipalityForm = ({isOpenAddMunicipalityForm, createNewMunicipality, isCreatingMunicipality}) => {

  const [region, setRegion] = useState(null);
  const [communes, setCommunes] = useState([]);
  const [municipalityName, setMunicipalityName] = useState(null);
  const [communeSelected, setCommuneSelected] = useState(null);
  const [adminEmail, setAdminEMail] = useState(null);

  const displayRegionsOptions = () => {
    const { regiones } = regions;
    return regiones.map((item, ix) => {
      return <option key={ix}>{item.region}</option>;
    });
  }

  const findCommunesByRegionSelected = (e) => {
    const { regiones } = regions;
    let regionSelected = e.target.value;

    setRegion(regionSelected);

    let singleRegion = regiones.filter((item, ix) => item.region === regionSelected);
    let communes = singleRegion[0].comunas.map((item, ix) => {
      return item;
    });

    setCommunes(communes);

    if(communes.length > 0) {
      setCommuneSelected(communes[0]);
    }
  }

  const displayCommunes = () => {
    return communes.map((item, ix) => {
      return <option key={ix}>{item}</option>;
    });
  }

  const handleInfo = (e) => {
    const { name, value } = e.target;
    setMunicipalityName(value);
  }

  const handleAdminEmail = (e) => {
    const { value } = e.target;
    setAdminEMail(value);
  }

  const saveCommuneSelected = (e) => {
    const { value } = e.target;
    setCommuneSelected(value);
  }

  const addMunicipality = () => {
    const newMunicipality = {
      name: municipalityName,
      region_name: region,
      city_name: communeSelected,
      adminEmail: adminEmail
    }
    createNewMunicipality(newMunicipality);
  }
  
  const creatingMunicipalityLoader = () => {
    if(isCreatingMunicipality) {
      return <Spinner />;
    } else {
      return 'Añadir Municipalidad';
    }
  }
  
  return(
    <ScaleFade in={isOpenAddMunicipalityForm}>
      <Box
      borderRadius={'5px'}
      maxW='500px'
      >
        <Stack spacing={'20px'}>
          <FormControl position={'relative'}>
            <Box position='absolute' mt={'-9px'} bg={'white'} pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
            <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Nombre Municipalidad</Text>
            </Box>
            <Input 
            variant={'outline'} 
            borderRadius='8px' 
            _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} 
            h='40px' 
            placeholder='Municipalidad...' 
            onChange={(e) => handleInfo(e)}
            name="name"></Input>
          </FormControl>

          <FormControl position={'relative'}>
            <Box position='absolute' mt={'-9px'} bg={'white'} pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
            <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Email Administrador</Text>
            </Box>
            <Input 
            variant={'outline'} 
            borderRadius='8px' 
            _placeholder={{color: '#9E9E9E', fontSize: '14px', fontWeight: '400', lineHeight: '143%'}} 
            h='40px' 
            placeholder='Ingresa el email del admin de la municipalidad' 
            onChange={(e) => handleAdminEmail(e)}
            name="email_administrador"></Input>
          </FormControl>

          <FormControl position={'relative'}>
            <Box position='absolute' mt={'-9px'} bg={'white'} pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
            <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Región</Text>
            </Box>
            <Select onChange={(e) => findCommunesByRegionSelected(e)}>{displayRegionsOptions()}</Select>
          </FormControl>

          <FormControl position={'relative'}>
            <Box position='absolute' mt={'-9px'} bg={'white'} pl={'5px'} pr={'5px'} ml={'18px'} zIndex='10'>
            <Text fontSize={'12px'} fontWeight='400' lineHeight={'156%'} color='#665'>Comuna</Text>
            </Box>
            <Select onChange={(e) => saveCommuneSelected(e)}>{displayCommunes()}</Select>
          </FormControl>

          <Button onClick={() => addMunicipality()}>{creatingMunicipalityLoader()}</Button>
        </Stack>
      </Box>
    </ScaleFade>
  )
}

export default CreateMunicipalityForm;