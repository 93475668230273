// React
import React, {useState, useEffect} from 'react';

// Chakra-ui
import {
    Box,
    Flex,
    Stack,
    Image,
} from '@chakra-ui/react';

// React-router-dom
import { useNavigate } from 'react-router-dom';

// Supabase client
import { supabase } from '../../../client';
import { daysInYear } from 'date-fns';

// Images & icons
const brandLogoIcon = require('../../../assets/images/brand_logo/brand-logo-icon.png');
const homeIcon = require('../../../assets/images/side-bar-icons/home.png');
const dashboardIcon = require('../../../assets/images/side-bar-icons/dashboard.png');
const eventsIcon = require('../../../assets/images/side-bar-icons/events.png');
const configIcon = require('../../../assets/images/side-bar-icons/config.png');
const questionsIcon = require('../../../assets/images/side-bar-icons/questions.png');
const logoutIcon = require('../../../assets/images/side-bar-icons/logout.png');
const alertIcon = require('../../../assets/images/side-bar-icons/alert-square.png');
const carIcon = require('../../../assets/images/navbar-icons/car.png');

const MainSideBar = () => {
  const navigate = useNavigate();
  const [userRoleId, setUserRoleId] = useState(null);

  useEffect(() => {
    getUserRole();
  }, []);

  const getUserRole = async() => {
    const userId = await supabase.auth.user().id;
    try {
        const { data } = await supabase
        .from('user_roles')
        .select('role_id')
        .eq('user_id', userId)
        if(data && data.length > 0) {
            setUserRoleId(data[0].role_id);
        }
    } catch (err) {
        console.log('There was an error getting the user', err);
    }
  }

  const logOut = async() => {
      const { error } = await supabase.auth.signOut();
      if(error) throw error;
      navigate('/');
  }

  return (
    <Box
    w='72px'
    display={'flex'}
    minH={'100%'} 
    pb={'300px'}
    bg={'#007472'}
    boxShadow={'2px 0px 16px 0px rgba(30, 48, 94, 0.08)'}
    >
      <Flex flexDir={'column'} alignItems='center'>
        <Box p={'16px'} h='81px' sx={{borderBottom: '1px solid #FFFFFF'}}>
            <Image cursor={'pointer'} w='auto' h='40px' src={brandLogoIcon} />
        </Box>
        <Box p={'16px'}>
        <Stack spacing={'16px'}>
            <Box display={'flex'} justifyContent='center' p='8px'>
                <Image cursor={'pointer'} onClick={() => navigate('/')} src={homeIcon} h='24px' w='24px'/>
            </Box>

            {userRoleId === 4 ? (
                <Box>
                    <Image cursor={'pointer'} onClick={() => navigate('/admin-dashboard')} src={dashboardIcon} />
                </Box>
            ):(
                <Box>
                    <Image cursor={'pointer'} onClick={() => navigate('/my-dashboard')} src={dashboardIcon} />
                </Box>
            )}

            <Box>
                <Image cursor={'pointer'} onClick={() => navigate('/events')} src={eventsIcon} />
            </Box>

            {/* <Box>
                <Image cursor={'pointer'} onClick={() => navigate('my-account')} src={configIcon} />
            </Box> */}

            <Box>
                <Image cursor={'pointer'} onClick={() => navigate('/faq')} src={questionsIcon} />
            </Box>

            <Box display={'flex'} justifyContent={'center'}>
                <Image cursor={'pointer'} onClick={() => navigate('/pickup-request/create')} h='24px' src={carIcon} />
            </Box>

        </Stack>
        </Box>

        <Box w='100%' onClick={() => logOut()} sx={{borderTop: '1px solid #FFFFFF'}}>
            <Flex justifyContent={'center'}>
              <Box mt='16px'>
                  <Image cursor={'pointer'} h='40px' w='40px' src={logoutIcon} />
              </Box>
            </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default MainSideBar;