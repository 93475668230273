// React
import React, { useEffect, useState } from 'react';

// React router dom
import { useNavigate } from 'react-router-dom';

// Chakra-ui
import {
  Text,
  Box,
  Stack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  useToast,
  Image
} from '@chakra-ui/react';

// Client
import { supabase } from '../../../../client';

// Images
const deleteIcon = require('../../../../assets/images/crud_icons/delete-icon.png');
const editIcon = require('../../../../assets/images/crud_icons/edit-icon.png');
const seeIcon = require('../../../../assets/images/crud_icons/eyes-icon.png');

const EventsList = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [events, setEvents] = useState([]);
  
  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async() => {
    const userId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('events')
      .select(`
      id,
      title
      `)
      .eq('user_id', userId)

      if(data.length > 0) {
        setEvents(data);
      }
    } catch (err) {
      console.log('error ', err);
    }
  } 

  const redirectTo = (type, id) => {
    switch (type) {
      case 'watch':
        navigate(`/events/${id}`);
        break;

      case 'edit':
        navigate(`/events/${id}/edit`);
        break;
    
      default:
        break;
    }
  }

  const eventDeletedToaster = () => {
    return(
      toast({
        title: 'Evento Eliminado',
        description: "",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const deleteEvent = async(id) => {
    try {
      await supabase
      .from('events')
      .delete()
      .eq('id', id);

      const newEvents = events.filter((item) => item.id !== id);
      setEvents(newEvents);
      
      eventDeletedToaster();

    } catch (err) {
      console.log('error ', err);
    }
  }


  const eventsListTable = () => {
    return(
      <Box className="events-list">
        <TableContainer maxW={'90%'}>
          <Table colorScheme='teal'>
            <Thead>
              <Tr>
                <Th>Id</Th>
                <Th>Titulo</Th>
                <Th isNumeric>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {events.map((event, ix) => {
                return(
                  <>
                  <Tr>
                  <Td>{event.id}</Td>
                  <Td>{event.title}</Td>
                  <Td isNumeric>
                    <Flex gap={4} justify='end'>
                      <Box cursor='pointer'>
                        <Image src={seeIcon} onClick={() => redirectTo('watch', event.id)} h={'20px'} w={'20px'} />
                      </Box>
                      <Box cursor='pointer'>
                        <Image src={editIcon} onClick={() => redirectTo('edit', event.id)} h={'20px'} w={'20px'} />
                      </Box>
                      <Box cursor='pointer'>
                        <Image src={deleteIcon} onClick={() => deleteEvent(event.id)} h={'20px'} w={'20px'} />
                      </Box>
                    </Flex>
                  </Td>
                  </Tr>
                  </>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return(
    <Box className="events-list-container">
      <Box ml={5} mt={7}>
        <Stack spacing={8}>
          <Box className="events-list-title">
            <Text fontSize={'xl'} fontWeight={600}>My events</Text>
          </Box>
          {(events && events.length > 0) ? (
            eventsListTable()
          ) : (
            <Text fontSize={'2xl'} fontWeight={600}>
              No events yet
            </Text>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default EventsList;