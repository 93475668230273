import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      categories: {
        title: "Categories",
        all: "All",
        preservation: "Preservation",
        energy: "Energy",
        transport: "Transport",
        water: "Water",
        food: "Nutrition",
        recycling: "Recycling",
        reforestation: "Reforestation",
        cleaning: "Cleaning",
        beekeeping: "Beekeeping",
        horticulture: "Horticulture",
        allAmbits: "All ambits"
      },
      itemCategoryTypes: {
        title: "Item types",
        all: "All",
        actions: "Actions",
        initiatives: "Initiatives",
        events: "Events",
      },
      genders: {
        male: "Male",
        female: "Mujer",
      }
    }
  },
  es: {
    translation: {
      categories: {
        title: "Categorías",
        all: "Todos",
        preservation: "Preservación",
        energy: "Energía",
        transport: "Transporte",
        water: "Agua",
        food: "Alimentación",
        recycling: "Reciclaje",
        reforestation: "Reforestación",
        cleaning: "Limpieza ambiental",
        beekeeping: "Apicultura",
        horticulture: "Horticultura",
        allAmbits: "Todos los ámbitos"
      },
      itemCategoryTypes: {
        title: "Tipos de item",
        all: "Todos",
        actions: "Acciones",
        initiatives: "Iniciativas",
        events: "Eventos"
      },
      genders: {
        male: "Hombre",
        female: "Mujer",
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es",
    fallbackLng: 'es', 
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;