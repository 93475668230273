// React
import React, { useEffect, useState } from 'react';

// React router dom
import { useNavigate } from 'react-router-dom';

// Chakra-ui
import {
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Flex,
  useToast,
  Image
} from '@chakra-ui/react';

// Client
import { supabase } from '../../../../client';

// Images
const deleteIcon = require('../../../../assets/images/crud_icons/delete-icon.png');
const editIcon = require('../../../../assets/images/crud_icons/edit-icon.png');
const seeIcon = require('../../../../assets/images/crud_icons/eyes-icon.png');

const InitiativesList = () => {
  const navigate = useNavigate();
  const [initiatives, setInitiatives] = useState([]);
  const toast = useToast();

  useEffect(() => {
    getInitiatives();
  }, []);

  const getInitiatives = async() => {
    const userId = await supabase.auth.user().id;
    try {
      const { data } = await supabase
      .from('initiatives')
      .select(`
      id,
      title,
      amount_collected,
      image_presentation,
      video_presentation,
      images
      `)
      .eq('user_id', userId)

      if(data.length > 0) {
        setInitiatives(data);
      }
    } catch (err) {
      console.log('error ', err);
    }
  } 

  const redirectTo = (type, id) => {
    switch (type) {
      case 'watch':
        navigate(`/initiatives/${id}`);
        break;

      case 'edit':
        navigate(`/initiatives/${id}/edit`);
        break;
    
      default:
        break;
    }
  }

  const initiativeDeletedToaster = () => {
    return(
      toast({
        title: 'Iniciativa Eliminada',
        description: "",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    )
  }

  const deleteImagePresentationFromBucket = async(id) => {
    try {
      const { data } = await supabase
      .from('initiatives')
      .select('image_presentation')
      .eq('id', id)

      if(data.length > 0) {
        const imagePresentation = data[0].image_presentation;
        await supabase
        .storage
        .from('initiatives-images')
        .remove(`presentation_image/${imagePresentation}`)
      }
    } catch (err) {
      console.error('Error while fetching image presentation from initiative ', err);
    }
  }

  const deleteVideoPresentationFromBucket = async(id) => {
    try {
      const { data } = await supabase
      .from('initiatives')
      .select('video_presentation')
      .eq('id', id)

      if(data.length > 0) {
        const videoPresentation = data[0].video_presentation;
        await supabase
        .storage
        .from('initiatives-images')
        .remove(`presentation_video/${videoPresentation}`)
      }
    } catch (err) {
      console.error('Error while fetching video presentation from initiative ', err);
    }
  }

  const deleteInitiativeImagesFromBucket = async(id) => {
    try {
      const { data } = await supabase
      .from('initiatives')
      .select('images')
      .eq('id', id)
      if(data.length > 0) {
        const initiativeImages = data[0].images;
        initiativeImages.forEach(async(image) => {
          await supabase
          .storage
          .from('initiatives-images')
          .remove(`initiative_images/${image}`)
        });
      }
    } catch (err) {
      console.error('Error while fetching images from initiative', err);
    }
  }

  const getAffiliationId = async(id) => {
    try {
      const { data } = await supabase
      .from('initiatives')
      .select('marketplace_affiliation_id')
      .eq('id', id)

      if(data.length > 0) {
        return data[0].marketplace_affiliation_id;
      } else {
        return null;
      }

    } catch (err) {
      console.error('Error while fetching video presentation from initiative ', err);
    }
  }

  const removeAffiliation = async(id) => {
    const affiliationId = await getAffiliationId(id);

    if(affiliationId) {
      try {
        let url = process.env.NODE_ENV === "development" ? `https://des.payku.cl/api/maaffiliation/${affiliationId}` : `https://app.payku.cl/api/maaffiliation/${affiliationId}`;
    
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.NODE_ENV === "development" ? process.env.REACT_APP_TEST_PAYKU_PUBLIC_TOKEN : process.env.REACT_APP_PROD_PAYKU_PUBLIC_TOKEN}`
          },
        });
      } catch (err) {
        console.log('Error while removing afiliation ', err);
      }
    }
  }

  const deleteInitiative = async(id) => {
   deleteImagePresentationFromBucket(id);
   deleteVideoPresentationFromBucket(id);
   deleteInitiativeImagesFromBucket(id);
   removeAffiliation(id);
     
  try {
    await supabase
    .from('initiatives')
    .delete()
    .eq('id', id);

    const newInitiatives = initiatives.filter((item) => item.id !== id);
    setInitiatives(newInitiatives);
    
    initiativeDeletedToaster();

  } catch (err) {
    console.log('error ', err);
  }

  }

  const initiativesListTable = () => {
    return(
      <Box className="initiatives-list">
        <TableContainer maxW={'90%'}>
          <Table colorScheme='teal'>
            <Thead>
              <Tr>
                <Th>Id</Th>
                <Th>Titulo</Th>
                <Th>Dinero Recolectado</Th>
                <Th isNumeric>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {initiatives.map((initiative, ix) => {
                return(
                  <>
                  <Tr>
                  <Td>{initiative.id}</Td>
                  <Td>{initiative.title}</Td>
                  <Td>{initiative.amount_collected}</Td>
                  <Td isNumeric>
                    <Flex gap={4} justify='end'>
                      <Box cursor='pointer'>
                        <Image src={seeIcon} onClick={() => redirectTo('watch', initiative.id)} h={'20px'} w={'20px'} />
                      </Box>
                      <Box cursor='pointer'>
                        <Image src={editIcon} onClick={() => redirectTo('edit', initiative.id)} h={'20px'} w={'20px'} />
                      </Box>
                      <Box cursor='pointer'>
                        <Image src={deleteIcon} onClick={() => deleteInitiative(initiative.id)} h={'20px'} w={'20px'} />
                      </Box>
                    </Flex>
                  </Td>
                  </Tr>
                  </>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return(
    <Box className="initiative-list-container">
      <Box ml={5} mt={7}>
        <Stack spacing={8}>
          <Box className="initiative-list-title">
            <Text fontSize={'xl'} fontWeight={600}>My initiatives</Text>
          </Box>
          {(initiatives && initiatives.length > 0) ? (
            initiativesListTable()
          ) : (
            <Text fontSize={'2xl'} fontWeight={600}>
              No initiatives yet
            </Text>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default InitiativesList;